import React, { useState, useEffect } from "react";
import constants from "../../utils/constants";
import request from "../../utils/request";
import axios from "axios";

import "./PasswordChange.scss";

const Verification = () => {
    const [processSucceed, setProcessSucceed] = useState(true);
    const [processDone, setProcessDone] = useState(false);
    const [passError, setPassError] = useState({
        message: "",
        show: false
    });
    const [viewModel, setViewModel] = useState({
        password: "",
        passwordAgain: ""
    });

    const changePassword = async () => {
        if (viewModel.password !== "" && viewModel.password === viewModel.passwordAgain && viewModel.password.length > 7) {
            const token = window.location.search.split("=")[1];
            const response = await request.postRequest(`/Auth/resetPassword`, {
                Password: viewModel.password,
                VerificationToken: token
            });
            if (response) {
                setProcessDone(true);
            } else {
                setProcessSucceed(false);
            }
        } else {
            if (viewModel.password !== viewModel.passwordAgain) {
                setPassError({
                    message: "Passwords dont match",
                    show: true
                });
            } else if (viewModel.password.length < 8) {
                setPassError({
                    message: "Password must be longer than 8 letters",
                    show: true
                });
            }
        }
    }

    return (
        <section id="password-site" className="page-section">
            <div className="container position-relative zindex-5 text-center" style={{display: processSucceed && !processDone ? "" : "none"}}>
                <h1 className="title-verify">Password reset</h1>
                <div className="col">
                    <div className="row input-row">
                        <p style={{display: passError.show ? "" : "none"}} className="warning">{passError.message}</p>
                    </div>
                    <div className="row input-row">
                        <label className="input-label">Password</label>
                        <input className="password-input" type="password" value={viewModel.password} onChange={e => setViewModel(viewModel => { return { ...viewModel, password: e.target.value }})}></input>
                    </div>
                    <div className="row input-row">
                        <label className="input-label">Password again</label>
                        <input className="password-input" type="password" value={viewModel.passwordAgain} onChange={e => setViewModel(viewModel => { return { ...viewModel, passwordAgain: e.target.value }})}></input>
                    </div>
                    <div className="row input-row">
                        <button className="reset-button" onClick={() => changePassword()}>Reset Password</button>
                    </div>
                </div>
            </div>
            <div className="container position-relative zindex-5 text-center" style={{display: processSucceed && processDone ? "" : "none"}}>
                <h1 className="title-verify">Password successfuly changed</h1>
                <i className="fas fa-heart fa-4x error-icon"></i>
            </div>
            <div className="container position-relative zindex-5 text-center" style={{display: !processSucceed && !processDone ? "" : "none"}}>
                <h1 className="title-verify">Something went wrong, try again later or contact our support.</h1>
                <i className="fas fa-heart-broken fa-4x error-icon"></i>
            </div>
        </section>
    )
}

export default Verification