import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PasswordChange from "../components/Sections/PasswordChange";

const ResetPassword = () => {
  return (
    <Layout>
        <SEO
            lang="ENG" 
            title="Tape Me"
            keywords={['badoo, tinder, TapeMe, love, person, geart, sex, date, Dating, datingsite, findlove, tapeme, voicemessage']}/>
        <PasswordChange />
    </Layout>
  )
}

export default ResetPassword
